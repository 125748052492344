/**
 * --------------------------------------------------------------------------
 * Bootstrap index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import Alert from '@/alert.js'
import Button from '@/button.js'
import Carousel from '@/carousel.js'
import Collapse from '@/collapse.js'
import Dropdown from '@/dropdown.js'
import Modal from '@/modal.js'
import Offcanvas from '@/offcanvas.js'
import Popover from '@/popover.js'
import ScrollSpy from '@/scrollspy.js'
import Tab from '@/tab.js'
import Toast from '@/toast.js'
import Tooltip from '@/tooltip.js'

export default {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Offcanvas,
  Popover,
  ScrollSpy,
  Tab,
  Toast,
  Tooltip
}
